import React, { FC, useState, useEffect, useRef } from 'react';
import gsap, { Power2 } from 'gsap';
import { Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import './Menu.scss';

export type MenuItem = {
  label: string;
  url?: string;
  id?: number;
  focused?: boolean;
};

export type MenuProps = {
  active: boolean;
  items: MenuItem[];
  onClick: () => void;
};

const Menu: FC<MenuProps> = (props) => {
  const [items, setItems] = useState(props.items);
  const [isItemFocused, setIsItemFocused] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
  const menuRef = useRef<HTMLDivElement>(null);
  const menuTl0 = useRef<any>(null);
  const menuTl1 = useRef<any>(null);
  const menuTl2 = useRef<any>(null);
  const menuTl3 = useRef<any>(null);
  const menuTl4 = useRef<any>(null);
  const q = gsap.utils.selector(menuRef);

  useEffect(() => {
    menuTl0.current = gsap.timeline({ paused: true }).to(q(`.menu-item--0`), {
      opacity: 1,
      x: isMobile ? '15%' : 50,
      duration: 0.6,
      delay: 0.8 + 0 * 0.15,
      ease: Power2.easeInOut,
    });

    menuTl1.current = gsap.timeline({ paused: true }).to(q(`.menu-item--1`), {
      opacity: 1,
      x: isMobile ? '15%' : 50,
      duration: 0.6,
      delay: 0.8 + 1 * 0.15,
      ease: Power2.easeInOut,
    });

    menuTl2.current = gsap.timeline({ paused: true }).to(q(`.menu-item--2`), {
      opacity: 1,
      x: isMobile ? '15%' : 50,
      duration: 0.6,
      delay: 0.8 + 2 * 0.15,
      ease: Power2.easeInOut,
    });

    menuTl3.current = gsap.timeline({ paused: true }).to(q(`.menu-item--3`), {
      opacity: 1,
      x: isMobile ? '15%' : 50,
      duration: 0.6,
      delay: 0.8 + 3 * 0.15,
      ease: Power2.easeInOut,
    });

    menuTl4.current = gsap.timeline({ paused: true }).to(q(`.menu-item--4`), {
      opacity: 1,
      x: isMobile ? '15%' : 50,
      duration: 0.6,
      delay: 0.8 + 4 * 0.15,
      ease: Power2.easeInOut,
    });
  }, []);

  useEffect(() => {
    if (props.active) {
      menuTl0.current.play();
      menuTl1.current.play();
      menuTl2.current.play();
      menuTl3.current.play();
      menuTl4.current.play();
    } else {
      menuTl4.current.reverse();

      setTimeout(() => {
        menuTl3.current.reverse();
      }, 100);

      setTimeout(() => {
        menuTl2.current.reverse();
      }, 150);

      setTimeout(() => {
        menuTl1.current.reverse();
      }, 250);

      setTimeout(() => {
        menuTl0.current.reverse();
      }, 400);
    }
  }, [props.active]);

  useEffect(() => {
    const focused = items.find((item) => item.focused === true);

    if (focused) {
      setIsItemFocused(true);
    } else {
      setIsItemFocused(false);
    }
  }, [items]);

  const handleMenuItemMouseEnter = (item: MenuItem) => {
    const newItems = items.map((element) => {
      return {
        ...element,
        focused: element.id === item.id,
      };
    });

    setItems(newItems);
  };

  const handleMenuItemMouseLeave = () => {
    const newItems = items.map((element) => {
      return {
        ...element,
        focused: false,
      };
    });

    setItems(newItems);
  };

  return (
    <div ref={menuRef} className="menu">
      <div className="menu__wrapper">
        <ul className="menu__list">
          {items.map((item, index) => (
            <li
              key={index}
              className={`menu__item menu-item menu-item--${index} ${
                item.focused || isMobile ? 'menu-item--focused' : ''
              } ${isItemFocused ? 'menu-item--focused-out' : ''}`}
              onMouseEnter={() => handleMenuItemMouseEnter(item)}
              onMouseLeave={() => handleMenuItemMouseLeave()}
            >
              <Link
                to={`${item.url}`}
                style={{ display: 'block' }}
                onClick={() => {
                  document.querySelector('html')?.classList.remove('scroll-hidden');
                  props.onClick();
                }}
              >
                <div className="menu-item__index">{`0${index + 1}/`}</div>
                <div className="menu-item__label">{item.label}</div>
                <div className="menu-item__grayed">{item.label}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
